import { useState } from 'react';

function App() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('https://formspree.io/f/xkndzynl', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        message: message
      })
    });
    if (response.ok) {
      alert('¡Tu solicitud ha sido enviada correctamente!');
    } else {
      alert('Ha ocurrido un error al enviar tu solicitud. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div>
      {/* <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>Para continuar, haz clic</div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>en Verifícame</div> */}

      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>Si deseas eliminar tu cuenta de la aplicación de El de Fiar, por favor, completa el siguiente formulario, envía tu solicitud</div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '25px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}> y en menos de 24 horas, tu cuenta será eliminada</div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '25px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>Ten en cuenta que esta acción será permanente y no podrás recuperar tus datos una vez completada.</div>
      <div style={{ color: '#041E41', fontWeight: 'bold', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', display: 'flex', marginBottom: '25px', textAlign: 'center', fontFamily: 'Lato', fontSize: '20px'}}>Al confirmar la eliminación de tu cuenta, perderás acceso a todas las funcionalidades y servicios asociados con tu perfil.</div>


      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <input type="text" placeholder="Correo electrónico" value={name} onChange={handleNameChange} style={{ marginBottom: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} required />
        <input type="text" placeholder="Nombre completo" value={name} onChange={handleNameChange} style={{ marginBottom: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} required />
        <textarea placeholder="Motivo de elminación de cuenta (opcional)" value={message} onChange={handleMessageChange} style={{ marginBottom: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc', minHeight: '100px', minWidth: '200px' }} optional />

        <button type="submit" style={{ backgroundColor: '#FF5733', color: 'white', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', border: 'none' }}>Enviar solicitud</button>
      </form>

    </div>
  );
}

export default App;
