import logo from './fiar--logo.png';
import './Navbar.css';

function Navbar() {
    return (
        <nav className="navbar">
        <div className="flex items-center">
            <img src={logo} alt="Logo" style={{ height: '85px', width: '155px' }} />
        </div>
        </nav>
    );
  }
  
  export default Navbar;